import React from "react";
import * as prismic from "@prismicio/client";
import { RichTextField } from "@prismicio/client";
// import { RichText } from 'prismic-reactjs';
// import { RichTextField } from "@prismicio/client";
import { Box, Flex, Icon, Image, Text } from "@chakra-ui/react";
import { Content } from "@prismicio/client";
import { PrismicRichText, SliceComponentProps } from "@prismicio/react";
import {
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
} from "@chakra-ui/react";
import styles from "./SplitSection.module.css";
import { WreathLaurelIcon } from "@/icons/wreath-laurel";
import FadeInWhenVisible from "@/components/FadeInWhenVisible/FadeInWhenVisible";
/**
 * Props for `SplitSection`.
 */
export type SplitSectionProps = SliceComponentProps<Content.SplitSectionSlice>;

/**
 * Component for "SplitSection" Slices.
 */
const SplitSection = ({ slice }: SplitSectionProps): JSX.Element => {
  const flipped = slice.primary.flipped;
  const backgroundColor = slice.primary.backgroundcolor;
  const imageUrl = slice.primary.image?.url;
  const imageAlt = slice.primary.image?.alt;
  const heading = slice.primary.heading;

  const isDefaultSlice = slice.variation === "default";

  const paragraph = isDefaultSlice ? slice.primary.paragraph : null;

  return (
    <Box
      className={styles.splitSection}
      backgroundColor={backgroundColor ? backgroundColor : "white"}
    >
      <FadeInWhenVisible>
        <Box
          className={["container", styles.splitSection__container].join(" ")}
        >
          <Flex
            className={[
              styles.splitSection__inner,
              flipped ? styles.flipped : null,
            ].join(" ")}
          >
            <Box
              className={[
                styles.imageWrapper,
                flipped ? styles.splitRowReverse : styles.row,
              ].join(" ")}
            >
              {imageUrl && (
                <Image
                  src={imageUrl ? imageUrl : "/eca-501.png"}
                  alt={imageAlt}
                />
              )}
            </Box>
            <Box className={[styles.contentWrapper].join(" ")}>
              <Text textStyle="h4" as="h2" className={styles.heading}>
                {prismic.asText(heading)}
              </Text>

              {isDefaultSlice && (
                <PrismicRichText field={slice.primary.paragraph} />
              )}

              {!isDefaultSlice && slice.items.length > 0 && (
                <List spacing="30px">
                  {slice.items.map((item, index) => (
                    <ListItem key={index}>
                      <Flex alignItems="center">
                        <WreathLaurelIcon fontSize="30px" marginRight="20px" />
                        <Text textStyle="p" as="p" margin="0">
                          {prismic.asText(item.listitem)}
                        </Text>
                      </Flex>
                    </ListItem>
                  ))}
                </List>
              )}
            </Box>
          </Flex>
        </Box>
      </FadeInWhenVisible>
    </Box>
  );
};

export default SplitSection;
