import { createIcon } from "@chakra-ui/react";

// using `path`
export const WreathLaurelIcon = createIcon({
  displayName: "Wreath-Laurel",
  viewBox: "0 0 640 512",
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <path d="M384 32c44.2 0 80 35.8 80 80c-44.2 0-80-35.8-80-80zM495.4 99.9C489.3 43.7 441.8 0 384 0H352V32c0 61.9 50.1 112 112 112h8.1c25.7 31.5 39.9 71 39.9 112c0 69.6-37 130.5-92.4 164.2l21.2 10.6 11.7 5.9 27.6 13.8c51.5 9 105.4-14.2 133-62.1l8-13.9 16-27.7-6.9-4c-7.1-4.1-14.5-7.4-22-9.9c-7-2.3-14.1-4-21.3-5C619.1 304.5 640 266.7 640 224V208 176h-8c-8.2 0-16.2 .8-24 2.4c-7.2 1.5-14.2 3.6-20.9 6.3c16.2-36.2 15.4-79.4-5.9-116.4l-8-13.9-16-27.7-6.9 4c-7.1 4.1-13.7 8.8-19.6 14.1c-17 15-29 34.2-35.3 55.1zM319.8 480l.2 0h76.2l60.6 30.3 14.3-28.6-64-32-3.4-1.7H400 320c-106 0-192-86-192-192c0-41 14.2-80.5 39.9-112H176c61.9 0 112-50.1 112-112V0H256C198.2 0 150.7 43.7 144.6 99.9c-6.3-20.9-18.3-40.1-35.3-55.1c-5.9-5.2-12.5-10-19.6-14.1l-6.9-4-16 27.7-8 13.9c-21.4 37-22.2 80.2-5.9 116.4c-6.7-2.7-13.7-4.8-20.9-6.3c-7.8-1.6-15.8-2.4-24-2.4H0v32 16c0 42.7 20.9 80.5 53.1 103.8c-7.2 1-14.3 2.7-21.3 5c-7.5 2.5-14.9 5.8-22 9.9l-6.9 4 16 27.7 8 13.9c29.8 51.7 90.2 74.7 145.1 59.4c6.9-1.9 13.7-4.4 20.3-7.6c8.7 6 17.8 11.5 27.3 16.2l-50.8 25.4 14.3 28.6L243.8 480h76zM176 112c0-26.2 12.6-49.4 32-64c13.4-10 30-16 48-16c0 44.2-35.8 80-80 80zM96 296v18.5C58.7 301.4 32 265.8 32 224V211.3c36.9 10.4 64 44.4 64 84.7zm448 18.5V296c0-40.3 27.1-74.2 64-84.7V224c0 41.8-26.7 77.4-64 90.5zM146 402.6l9.3 16.1c-38.9 7.2-79.8-10.2-100.7-46.4l-6.3-11c37.2-9.4 77.6 6.5 97.8 41.3zM96.7 194.7C71 164.6 65.7 120.5 86.6 84.3l6.3-11c26.8 27.5 33.2 70.5 13.1 105.3l-9.3 16.1zM534 178.6c-20.1-34.9-13.7-77.8 13.1-105.3l6.3 11c20.9 36.2 15.5 80.4-10.2 110.4L534 178.6zm-40 224c20.1-34.9 60.6-50.8 97.8-41.3l-6.3 11c-20.9 36.2-61.8 53.6-100.7 46.4l9.3-16.1z" />
  ),
});

// // OR using the `d` value of a path (the path definition) directly
// export const UpDownIcon = createIcon({
//   displayName: "UpDownIcon",
//   viewBox: "0 0 200 200",
//   d: "M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0",
// });
